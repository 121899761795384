const destinationList = {
  ACTIVE_CAMPAIGN: require('./ACTIVE_CAMPAIGN.json'),
  ADJ: require('./ADJ.json'),
  ADOBE_ANALYTICS: require('./ADOBE_ANALYTICS.json'),
  AF: require('./AF.json'),
  ALGOLIA: require('./ALGOLIA.json'),
  AWIN: require('./AWIN.json'),
  AM: require('./AM.json'),
  APPCENTER: require('./APPCENTER.json'),
  APPCUES: require('./APPCUES.json'),
  AUTOPILOT: require('./AUTOPILOT.json'),
  AZURE_BLOB: require('./AZURE_BLOB.json'),
  AZURE_EVENT_HUB: require('./AZURE_EVENT_HUB.json'),
  AZURE_SYNAPSE: require('./AZURE_SYNAPSE.json'),
  BINGADS: require('./BINGADS.json'),
  BQ: require('./BQ.json'),
  BRANCH: require('./BRANCH.json'),
  BRAZE: require('./BRAZE.json'),
  BUGSNAG: require('./BUGSNAG.json'),
  CHARTBEAT: require('./CHARTBEAT.json'),
  CLEVERTAP: require('./CLEVERTAP.json'),
  CLICKHOUSE: require('./CLICKHOUSE.json'),
  CONFLUENT_CLOUD: require('./CONFLUENT_CLOUD.json'),
  CRITEO: require('./CRITEO.json'),
  CUSTOMERIO: require('./CUSTOMERIO.json'),
  DELIGHTED: require('./DELIGHTED.json'),
  DIGITAL_OCEAN_SPACES: require('./DIGITAL_OCEAN_SPACES.json'),
  DRIP: require('./DRIP.json'),
  EVENTBRIDGE: require('./EVENTBRIDGE.json'),
  FACEBOOK_PIXEL: require('./FACEBOOK_PIXEL.json'),
  FB: require('./FB.json'),
  FB_CUSTOM_AUDIENCE: require('./FB_CUSTOM_AUDIENCE.json'),
  FIREBASE: require('./FIREBASE.json'),
  FIREHOSE: require('./FIREHOSE.json'),
  FULLSTORY: require('./FULLSTORY.json'),
  GA360: require('./GA.json'),
  GA4: require('./GA4.json'),
  GA: require('./GA.json'),
  GAINSIGHT: require('./GAINSIGHT.json'),
  GAINSIGHT_PX: require('./GAINSIGHT_PX.json'),
  GCS: require('./GCS.json'),
  GOOGLEADS: require('./GOOGLEADS.json'),
  GOOGLEPUBSUB: require('./GOOGLEPUBSUB.json'),
  GOOGLESHEETS: require('./GOOGLESHEETS.json'),
  GTM: require('./GTM.json'),
  HEAP: require('./HEAP.json'),
  HOTJAR: require('./HOTJAR.json'),
  HS: require('./HS.json'),
  INDICATIVE: require('./INDICATIVE.json'),
  INTERCOM: require('./INTERCOM.json'),
  ITERABLE: require('./ITERABLE.json'),
  KAFKA: require('./KAFKA.json'),
  KEEN: require('./KEEN.json'),
  KINESIS: require('./KINESIS.json'),
  KISSMETRICS: require('./KISSMETRICS.json'),
  KLAVIYO: require('./KLAVIYO.json'),
  KOCHAVA: require('./KOCHAVA.json'),
  KUSTOMER: require('./KUSTOMER.json'),
  LEANPLUM: require('./LEANPLUM.json'),
  LINKEDIN_INSIGHT_TAG: require('./LINKEDIN_INSIGHT_TAG.json'),
  LOTAME: require('./LOTAME.json'),
  LOTAME_MOBILE: require('./LOTAME_MOBILE.json'),
  LYTICS: require('./LYTICS.json'),
  MAILCHIMP: require('./MAILCHIMP.json'),
  MARKETO: require('./MARKETO.json'),
  MARKETO_BULK_UPLOAD: require('./MARKETO_BULK_UPLOAD.json'),
  MINIO: require('./MINIO.json'),
  MOENGAGE: require('./MOENGAGE.json'),
  MONETATE: require('./MONETATE.json'),
  MP: require('./MP.json'),
  MSSQL: require('./MSSQL.json'),
  OMETRIA: require('./OMETRIA.json'),
  OPTIMIZELY: require('./OPTIMIZELY.json'),
  OPTIMIZELY_FULLSTACK: require('./OPTIMIZELY_FULLSTACK.json'),
  PENDO: require('./PENDO.json'),
  PERSONALIZE: require('./PERSONALIZE.json'),
  PINTEREST_TAG: require('./PINTEREST_TAG.json'),
  PIPEDRIVE: require('./PIPEDRIVE.json'),
  POSTGRES: require('./POSTGRES.json'),
  POSTHOG: require('./POSTHOG.json'),
  PROFITWELL: require('./PROFITWELL.json'),
  QUALTRICS: require('./QUALTRICS.json'),
  QUANTUMMETRIC: require('./QUANTUMMETRIC.json'),
  RECURLY: require('./RECURLY.json'),
  REDDIT_PIXEL: require('./REDDIT_PIXEL.json'),
  REDIS: require('./REDIS.json'),
  RS: require('./RS.json'),
  S3: require('./S3.json'),
  S3_DATALAKE: require('./S3_DATALAKE.json'),
  SALESFORCE: require('./SALESFORCE.json'),
  SEGMENT: require('./SEGMENT.json'),
  SENDGRID: require('./SENDGRID.json'),
  SENTRY: require('./SENTRY.json'),
  SFMC: require('./SFMC.json'),
  SLACK: require('./SLACK.json'),
  SNAP_PIXEL: require('./SNAP_PIXEL.json'),
  SNOWFLAKE: require('./SNOWFLAKE.json'),
  SPLITIO: require('./SPLITIO.json'),
  TRENGO: require('./TRENGO.json'),
  TVSQUARED: require('./TVSQUARED.json'),
  USERLIST: require('./USERLIST.json'),
  VARIANCE: require('./VARIANCE.json'),
  VWO: require('./VWO.json'),
  WEBHOOK: require('./WEBHOOK.json'),
  ZENDESK: require('./ZENDESK.json'),
};

export {
  destinationList
}
